import React, { useState, useEffect } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, TextField, Button, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Editor from '../TextEditorComponents/Editor';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@mui/material/Modal';



const Popup = ({data, onSave, onClose})=>{
const [editPlan, setEditPlan] = useState(data.editPlan);


const [testPlan, setTestPlan] = useState([]);


const handleSave = () => {
    const data = {
        editPlan:false,
        testPlan: testPlan
    }
    onSave(data);
};



const handleStepsFromEditor = (editor) => { 
    //Populate data field to send to editor
    setTestPlan({...testPlan, steps:editor});

};

const handleResultsFromEditor = (editor) => { 
  //Populate data field to send to editor
  setTestPlan({...testPlan, expectedResult:editor});

};


const closeModal = () => {
    onClose(false);
}


    return(
<Modal open={editPlan} onClose={closeModal}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height:800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" component="h2">
        Edit Test Plan
      </Typography>
      <Table>
      <TableHead>
      <TableCell><h3>Test Steps</h3></TableCell>
      <TableCell><h3>Expected Results</h3></TableCell>
      </TableHead>
      <TableRow>
        <TableCell><Editor prevText={data.testPlan.steps} sendTextFromEditor={handleStepsFromEditor}/></TableCell>
        <TableCell><Editor prevText={data.testPlan.expectedResult} sendTextFromEditor={handleResultsFromEditor}/></TableCell>
      </TableRow>
      
      <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2 }}>
        Save
      </Button>
      </Table>
      
    </Box>
  </Modal>
    )
    

    
}
;

export default Popup;
