const CACHE_EXPIRATION_TIME = 60 * 60 * 1000; // Default expiration time: 1 hour

// Save data to cache with an expiration time
export const saveCache = (key, data, expirationTime = CACHE_EXPIRATION_TIME) => {
  const cacheData = {
    value: data,
    timestamp: new Date().getTime(),  // Store the time of caching
    expirationTime  // Set expiration time in milliseconds (default 1 hour)
  };
  localStorage.setItem(key, JSON.stringify(cacheData));
};

// Load data from cache if it exists and is not expired
export const loadCache = (key) => {
  const cacheData = JSON.parse(localStorage.getItem(key));
  if (!cacheData) return null;  // No cache found

  const now = new Date().getTime();
  if (now - cacheData.timestamp > cacheData.expirationTime) {
    localStorage.removeItem(key);  // Cache has expired, so remove it
    return null;
  }

  return cacheData.value;  // Return the cached data
};

// Check if a cache is expired without loading it
export const isCacheExpired = (key) => {
  const cacheData = JSON.parse(localStorage.getItem(key));
  if (!cacheData) return true;  // If no cache, consider it expired

  const now = new Date().getTime();
  return now - cacheData.timestamp > cacheData.expirationTime;
};

// Clear specific cache by key
export const clearCache = (key) => {
  localStorage.removeItem(key);
};

// Clear all caches (optional, depending on your needs)
export const clearAllCache = () => {
  localStorage.clear();
};
