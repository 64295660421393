import React, {useState, useEffect} from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';



const MultiLevel = ({ item, handleClick, handleRequest }) => {

  const [listOpen, setListOpen] = useState(false);

  const handleListExpand = () => {
      setListOpen(!listOpen);
  };

    return (
      <div>
      <ListItemButton onClick={()=>{handleListExpand(); handleClick(item.id)}}>
          <ListItemIcon >
              {item.icon || <FolderIcon />}
          </ListItemIcon>
          <ListItemText primary={item.title} />
          {listOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={listOpen} timeout="auto" unmountOnExit>
          <List sx={{ pl: 4 }} component="div" disablePadding>
              {item.values && item.values.map((value) => (
                  value.values ? (
                      // Recursively render `MultiLevel` if the item has a sub-list
                      <MultiLevel key={value.id} item={{...value}} handleClick={()=>handleClick(value.id)} handleRequest={handleRequest} />
                  ) : (
                      // Render a simple list item if there are no further sub-lists
                      <ListItemButton key={value.id} onClick={()=>handleRequest(value.id)}>
                          <ListItemIcon>
                              {item.icon || <DescriptionIcon/>}
                          </ListItemIcon>
                          <ListItemText primary={value.name} />
                      </ListItemButton>
                  )
              ))}
          </List>
      </Collapse>
  </div>
    );
  };

  export default MultiLevel;