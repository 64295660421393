import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // useNavigate is valid here
import Sidenav from './components/NavigationComponents/Sidenav';
import Login from './pages/Login';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);



  return (
    <div>
      <Routes>
      <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
      <Route path='/*' element={<Sidenav setIsLoggedIn={setIsLoggedIn} />} />
      </Routes>
    </div>

  );
}

export default App;
