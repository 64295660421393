import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";

const TableGenerator = ({ data }) => {

  if (!data || !data.columns || !data.rows) {
    console.error("Invalid data provided to TableGenerator");
    return null;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        {/* Table Header */}
        <TableHead>
          <TableRow>
            {data.columns.map((column, colIndex) => {
              if(column.columnTitleType){
                // Handle buttons
                return (
                  <TableCell key={colIndex}>
                    {column.columnTitleType}
                  </TableCell>
                );
              }
              return <TableCell key={colIndex}>{column.label}</TableCell>
              }
            )}
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {data.rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {data.columns.map((column, colIndex) => {
                const cellValue = row[column.key];

                if (column.type === "specialCell") {
                  // Handle checkboxes
                  return (
                    <TableCell key={colIndex}>
                      {cellValue.value}
                    </TableCell>
                  );
                }
                // Handle text and other types
                return <TableCell key={colIndex}>{cellValue}</TableCell>;
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableGenerator;
