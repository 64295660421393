// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `    /* Editor.css */

/* Limit image width inside the editor to avoid overflow */
.ql-editor img {
    max-width: 100%;  /* Ensures the image takes no more than the container's width */
    height: auto;     /* Maintains the aspect ratio */
  }
  
  /* Add some padding to the editor container */
  .editor-container {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
  }
  
  .ql-toolbar {
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensures it stays above other content */
    background-color: white; /* Matches the editor background */
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/TextEditorComponents/Editor.css"],"names":[],"mappings":"IAAI,eAAe;;AAEnB,0DAA0D;AAC1D;IACI,eAAe,GAAG,+DAA+D;IACjF,YAAY,MAAM,+BAA+B;EACnD;;EAEA,6CAA6C;EAC7C;IACE,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,MAAM;IACN,aAAa,EAAE,yCAAyC;IACxD,uBAAuB,EAAE,kCAAkC;EAC7D","sourcesContent":["    /* Editor.css */\n\n/* Limit image width inside the editor to avoid overflow */\n.ql-editor img {\n    max-width: 100%;  /* Ensures the image takes no more than the container's width */\n    height: auto;     /* Maintains the aspect ratio */\n  }\n  \n  /* Add some padding to the editor container */\n  .editor-container {\n    height: 100%;\n    overflow-y: auto;\n    box-sizing: border-box;\n  }\n  \n  .ql-toolbar {\n    position: sticky;\n    top: 0;\n    z-index: 1000; /* Ensures it stays above other content */\n    background-color: white; /* Matches the editor background */\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
