import * as React from 'react';
import {useEffect, useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListSubheader from '@mui/material/ListSubheader';
import SingleLevel from '../ListComponents/SingleLevel';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { getProjectsList } from '../../utils/requestManager';
import { saveCache, loadCache, isCacheExpired, clearCache, clearAllCache } from '../../utils/cacheManager'; // Import cacheManager functions
import MultiLevel from '../ListComponents/MultiLevel';
import Requirements from '../../pages/Requirements';
import TestPlan from '../../pages/TestPlan';
import Project from '../../pages/Project';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // useNavigate is valid here
import CreateProject from '../../pages/CreateProject';
import Home from '../../pages/Home';

const docsList = [
  {id:'PRS', name:'Product Requirements Specification'},
  {id:'SRS', name:'Software Requirements Specification'},
  {id:'SDS', name:'System Design Specification'},
  {id:'UTP', name:'Unit Test Plans'},
  {id:'ITP', name:'Integration Test Plans'},
  {id:'STP', name:'System Test Plans'},
]
const containerStyles =  {
      flexGrow: 1,
      p:8, 
}

const pageDictionary = {
  requirements:['SRS', 'PRS', 'SDS'],
  testPlans: ['ITP', 'STP', 'UTP']
}

const createProjectStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width:'auto'
}


const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'auto',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
          
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

const CACHE_KEY_USER_DATA = 'userData';  // Key to store user data in cache
const CACHE_KEY_PROJECT_DATA = 'projectsList';  // Key to store project data in cache


export default function Sidenav() {
  const theme = useTheme();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [open, setOpen] = useState(true);
  const [listOpen, setListOpen] = useState(false);
  const [user, setUser] = useState('');
  const [projects, setProjects] = useState([]);
  const [isHome, setIsHome] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const [showReqPage, setShowReqPage] = useState(false);
  const [showTestPlans, setShowTestPlans] = useState(false);
  const [showProjectPage, setShowProjectPage] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); 


  const uri = process.env.REACT_APP_URI.trim('');  // Backend URI


  const navigate = useNavigate();
  // Function to fetch user data if cache is expired or doesn't exist
  const fetchUserData = async () => {
      const url = `${uri}/auth/user`;
      const data = {
        url: url,
      };
      getProjectsList(data).then((result) => {
        if (result) {
          const pjtList = result.projects.map(project => ({
            id: project.id,
            title: project.name,
            values:docsList,
        }));
          setProjects(pjtList);
          saveCache(CACHE_KEY_USER_DATA, {...result.user, isAdmin: result.isAdmin});
          saveCache(CACHE_KEY_PROJECT_DATA, pjtList);
          setUser(result.user.displayName); 
          setIsAdmin(result.isAdmin);  
        } else {
          navigate('/');  // If not authenticated, redirect to login
        }
    });
  };



  useEffect(() => {
    // Check if cache exists and is not expired
    const cachedUser = loadCache(CACHE_KEY_USER_DATA);
    const cachedProjects = loadCache(CACHE_KEY_PROJECT_DATA);

    if (cachedUser && cachedProjects && !isCacheExpired(CACHE_KEY_USER_DATA)) {
      // Use cached data
      setUser(cachedUser.displayName);
      setProjects(cachedProjects);
      setIsAdmin(cachedUser.isAdmin);
      setLoading(false); // Stop loading spinner if data is cached
    } else {
      // Fetch new data if cache doesn't exist or is expired
      fetchUserData();
    }
    
  }, [navigate, uri, user, isAdmin]);

  const checkAuthAndFetchProjectData = async (projectId) => {
    if(projectId){
    const queryParams = new URLSearchParams({ projectId });

    try {
      const response = await fetch(`${uri}/auth/get-project?${queryParams}`, {
        method: 'GET',
        credentials: 'include',  // Send cookies with request
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        return data
      } 
    } catch (error) {
        console.error('Error fetching project data:', error);
        return({message: error});
    }
  }
  };


  const handleRequest = (page) => {
    const appendData = {
      page:page
    }
    const queryProjectData = {...projectData, ...appendData};
    if (pageDictionary.requirements.includes(page)){
      navigate(`/project/requirements/`, {state:queryProjectData});
      setIsHome(false);
    }
    else if (pageDictionary.testPlans.includes(page)){
      navigate(`/project/testplan/`, {state:queryProjectData});
      setIsHome(false);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    clearAllCache();
    try {
      const response = await fetch(`${process.env.REACT_APP_URI.trim('')}/auth/logout`, {
        method: 'POST',
        credentials: 'include',  // Include cookies in the logout request
      });

      if (response.ok) {
        setIsLoggedIn(false);  // Set logged-in state to false
        setLoading(false);  // Set loading to false
        clearAllCache();  // Clear cache to ensure fresh data is fetched next time
        navigate('/');  // Redirect to login page after logout
      } else {
        console.error('Error during logout');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
    navigate('/');
  };

  const handleProjectClick = async (projectId) => {
    const response = await checkAuthAndFetchProjectData(projectId);
    if(response){
      setProjectData(response);
      setIsHome(false);
      setShowProjectPage(true);
      navigate(`/project/`, {state:response});
    }
  };

  const handleHome = () => {
    navigate('/home');
    setIsHome(true);
  }

  const handleCreateProject = ()=>{
    setIsHome(false);
    navigate('/create-project');
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                marginRight: 5,
              },
              open && { display: 'none' },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Mimyk DHF Portal
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Menu
        </ListSubheader>
      }
    >
      <SingleLevel item={{title:'Home', icon:<HomeIcon/>}} handleClick={handleHome}/>
      {projects && <MultiLevel item={{title:'Projects List', icon:<FolderIcon/>, listOpen:listOpen, values:projects}} 
      handleClick={handleProjectClick} handleRequest={handleRequest}/>}
    </List>
    <Box mt={70}> 
        <Divider />
        <List>
        <SingleLevel item={{title:'Logout', icon:<ExitToApp/>}} handleClick={handleLogout}/>
        </List>
        </Box>
      </Drawer>
      
      <Routes>
          <Route path='/project' element={
            <Box component="main" sx={containerStyles}>
            <Project />
            </Box>
            } />
          <Route path='/project/requirements' element={
            <Box component="main" sx={containerStyles}>
            <Requirements />
          </Box>
          } 
            />
          <Route path='/project/testplan' element={
            <Box component="main" sx={containerStyles}>
            <TestPlan />
            </Box>
            } />
          <Route path='/create-project' element={
            <Box component="main" sx={containerStyles}>
              <CreateProject />
            </Box>
            } />
      </Routes>
      
      {isHome && <Box component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }}}>
        <DrawerHeader />
        <Typography sx={{ marginBottom: 2 }}>
        Hi {user}. 
        </Typography>
        <Home />
        {isAdmin && <Box sx={{ 
            position: 'fixed', 
            bottom: '20px', // Distance from the bottom of the viewport
            right: '20px',  // Distance from the right of the viewport
          }}>
        <Button size='sizeLarge' variant="contained" color="info" startIcon={<AddCircleIcon />} onClick={handleCreateProject} ></Button>
      </Box>}
      </Box>}

     
      

    </Box>
  );
}
