import React, { useState, useEffect } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Editor from '../components/TextEditorComponents/Editor';
import EditIcon from '@material-ui/icons/Edit';
import Popup from '../components/PopupComponents/Popup';
import {sheetAccess, sheetLastViewed} from '../utils/requestManager';
import { saveCache, loadCache, isCacheExpired, clearCache } from '../utils/cacheManager';
import { ToastContainer, toast } from 'react-toastify';  // Import react-toastify
import 'react-toastify/dist/ReactToastify.css';  // Import the CSS for the toast notifications
import MultiLevel from '../components/ListComponents/MultiLevel';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DescriptionIcon from '@mui/icons-material/Description';
import TableGenerator from '../components/ListComponents/TableGenerator';

const TestPlan = () => {
  const [testPlans, setTestPlans] = useState([]);
  const [viewList, setViewList] = useState(false);
  const [list, setList] = useState("View");
  const [editPlan, setEditPlan] = useState(false);
  const [editorData, setEditorData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const [testPlan, setTestPlan] = useState({
    testId: '',
    id: '',
    title: '',
    steps: '',
    expectedResult: '',
  });

  const location = useLocation();
  const projectData = location.state;
  const projectId = projectData['projectId'];
  const page = projectData['page'];
  const sheetId = projectData['sheetId'];

  const testDictionary = {
    ITP: { title: 'Integration Test Plan', testId: 'IT' },
    UTP: { title: 'Unit Test Plans', testId: 'UT' },
    STP: { title: 'System Test Plans', testId: 'ST' },
  };

  const [title, setTitle] = useState('');
  const [lastEdited, setLastEdited] = useState({
    timestamp: null,
    user: null,
  });


  const queryString = new URLSearchParams(projectData).toString();
  const baseUrl = process.env.REACT_APP_URI.trim('');
  const url = `${baseUrl}/auth/dhf/testplans`;

  const data = {
    method: '',
    url: url,
    queryString: queryString,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    data2Post: {
      testPlans: testPlans,
      page: page,
      sheetId: sheetId,
    },
  };

  const CACHE_KEY_TEST_PLANS = `${projectId}_${page}_TestPlansCache`;
  const CACHE_KEY_EDITOR_DATA = `${projectId}_${page}_EditorDataCache`;

  useEffect(() => {
    setTitle(testDictionary[page].title);

    const cachedTestPlans = loadCache(CACHE_KEY_TEST_PLANS);
    const cachedEditorData = loadCache(CACHE_KEY_EDITOR_DATA);

    if (cachedTestPlans && !isCacheExpired(CACHE_KEY_TEST_PLANS)) {
      setTestPlans(cachedTestPlans);
    } else {
      sheetAccess(data).then((result) => {
        if (result) {
          setTestPlans(result);
          saveCache(CACHE_KEY_TEST_PLANS, result);
        }
      });
    }

    if (cachedEditorData && !isCacheExpired(CACHE_KEY_EDITOR_DATA)) {
      setEditorData(cachedEditorData);
    }
    const data4lastViewed = {
      url:`${baseUrl}/auth/get-change-log`,
      fileId:sheetId
    }
    sheetLastViewed(data4lastViewed).then((result) => {
      if(result){
        setLastEdited({
          timestamp: result.lastModifiedTime,
          user: result.lastModifyingUser.displayName,
        });
      }
}).catch((error) => {console.error('Error: ', error)});
  }, [page]);

  const handleToggleViewList = () => {
    setList(viewList ? "View" : "Hide");
    setViewList(!viewList);
  };

  const handleEdit = (plan) => {
    setTestPlan({
      testId: plan.testId,
      id: plan.id,
      title: plan.title,
      steps: plan.steps || '',
      expectedResult: plan.expectedResult || '',
    });
    setEditPlan(true);
  };

  const handleSavePlan = (val) => {
    setEditPlan(val.editPlan);
    const updatedTest = { ...testPlan, ...val.testPlan };
    setTestPlan(updatedTest);
  
    const updatedPlans = testPlans.map((plan) =>
      plan.testId === updatedTest.testId ? { ...plan, ...updatedTest } : plan
    );
    setTestPlans(updatedPlans);
  
    data.method = 'POST';
    data.data2Post.testPlans = updatedPlans;
  
    // Clear cache after saving test plans
    clearCache(CACHE_KEY_TEST_PLANS);
  
    // Show saving toast
    const toastId = toast.info('Saving test plans...');  // Keep the toast visible until we manually dismiss/update it.
  
    // Save test plans and update the toast upon success or failure
    sheetAccess(data)
      .then((result) => {
        if (result) {
          setTestPlans(result);
          saveCache(CACHE_KEY_TEST_PLANS, result);  // Save the updated test plans to cache
          
          // Update the toast on success and close after 3 seconds
          toast.update(toastId, {
            render: 'Test plans saved successfully!',
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,  // Close after 3 seconds
          });
        }
      })
      .catch((error) => {
        // Update the toast on error and keep it visible for 5 seconds
        toast.update(toastId, {
          render: 'Error saving test plans!',
          type: toast.TYPE.ERROR,
          autoClose: 5000,  // Close after 5 seconds
        });
        console.error(error);
      });
  };
  
  const handleTextFromEditor = (text) => {
    setEditorData(text);
    saveCache(CACHE_KEY_EDITOR_DATA, text);
  };

  const handleClose = (hide) => {
    setEditPlan(hide);
  };

  return (
    <Container>
      <Box mt={4}>
      <Typography variant="h5" gutterBottom>
        Project ID: {projectId}
      </Typography>
      </Box>
      
      <br></br>
      <center><Typography variant="h4" gutterBottom>
        {title}
      </Typography></center>
      <Typography variant="h7" gutterBottom> 
      {lastEdited && <span style={{fontSize: '1.2rem'}}>Last Edited: {JSON.stringify(lastEdited.timestamp)} by {JSON.stringify(lastEdited.user)}</span>}
      </Typography>
      <br />
      <br />
      <br />
      <MultiLevel item={{title:'Add Additional Details', icon:<DescriptionIcon/>}} handleClick={()=>{setShowDetails(!showDetails)}}/>
      {showDetails && <Editor sendTextFromEditor={handleTextFromEditor} />}
      <br />
      <MultiLevel item={{title:'List of Test Cases', icon:<FormatListNumberedIcon/>}} handleClick={()=>{setViewList(!viewList)}}/>

      {viewList && (
        <TableGenerator data={{
          columns:[
            { key:"testId", label:"Test ID", type:"text" },
            { key: "id", label: "Traceable Requirement", type: "text"},
            { key: "title", label: "Requirement Title", type: "text"},
            { key: "actions", label: "Actions", type: "specialCell"},
            // { key: "actions", label: "Actions", type: "actions" }
          ],
          rows: testPlans.map((plan, index) => ({
            actions: {
              value: <EditIcon cursor="pointer" color="primary" onClick={() => handleEdit(plan)} />,
            },
            ...plan,
          }))
        }}/>
        )}
      {editPlan && <Popup data={{ editPlan: editPlan, testPlan: testPlan }} onSave={handleSavePlan} onClose={handleClose} />}

      {/* Toast Container for Notifications */}
      <ToastContainer />
    </Container>
  );
};

export default TestPlan;
