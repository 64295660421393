import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Typography, Button, Box } from '@mui/material';
import Modal from '@mui/material/Modal';

import Editor from '../TextEditorComponents/Editor';



const modalStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 1.0)',
  padding: '20px',
  height: '100vh',
  width: '90vw',
  margin: 'auto',
  overflow: 'hidden',
};


const editorScrollContainerStyle = {
  flex: 1,
  width: '100%',
  overflowY: 'auto', // Enables scrolling for content
  border: '1px solid #ccc',
  borderRadius: '4px',
};

// Use forwardRef to allow parent to trigger functions in the child component
const AddDetailsPopup = forwardRef(({ data, onSave, onClose }) => {
  const [showDetails, setShowDetails] = useState(data.showDetails);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    setShowDetails(data.showDetails);
    setDetails(data.details);
  }, [data]);

  const handleSave = () => {
    const data = {
      showDetails: false,
      details: details
    };
    onSave(data);
  };

  const handleStepsFromEditor = (editor) => {
    setDetails(editor);
  };

  const closeModal = () => {
    onClose(false);
  };

  return (
    <Modal open={showDetails} onClose={closeModal}>
      <Box
        sx={modalStyle}
      >
        <Typography variant="h6" component="h2">
          Edit Additional Details
        </Typography>
        <Box sx={editorScrollContainerStyle}>
        <Editor
          sx={{
            flex: 1,
            overflowY: 'auto',
            mb: 2,
          }}
          prevText={data.details}
          sendTextFromEditor={handleStepsFromEditor}
        />
        </Box>
        <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2 }}>
          Update Details
        </Button>
      </Box>
    </Modal>
  );
});

export default AddDetailsPopup;
