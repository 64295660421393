
import React from 'react';
import { Typography, Box, Toolbar, List, ListItem } from '@mui/material';

const Paragraph = ({children}) => {
  return (
    <Typography variant='body1' sx={{ marginBottom: 2 }}>
      {children}
    </Typography>
  )
}

const SectionHeading = ({children}) => {
  return (
    <Typography variant='h5' sx={{ marginBottom: 2 }}>
      {children}
    </Typography>
  )
}

const SubSectionHeading = ({children}) => {
  return (
    <Box sx={{ml:2}}>
    <Typography variant='h6' sx={{ marginBottom: 2 }}>
      {children}
    </Typography>
    </Box>
  )
}

const BulletPoint = ({children}) => {
  return (
    <ListItem>
      {children}
    </ListItem>
  )
}

const NumberedList = ({ items }) => {
  return (
    <Box sx={{ml:4}}>
    <List sx={{ listStyleType: 'decimal', pl: 4 }}>
      {items.map((item, index) => (
        <ListItem key={index} sx={{ display: 'list-item' }}>
          {item}
        </ListItem>
      ))}
    </List>
    </Box>
  );
};


const Home = () => {


  return(
    <Box>
      <Typography sx={{ marginBottom: 2 }}>
      
      </Typography>

      <SectionHeading>
        About MIMYK DHF Portal
      </SectionHeading>
      <Paragraph>
      The Design History File (DHF) Portal is a platform developed to streamline the management and maintenance of documentation related to 
      medical devices designed and manufactured at Mimyk. It aligns with Mimyk's Product Development Life Cycle (PDLC) Standard Operating Procedure (SOP), 
      ensuring compliance with regulatory standards and efficient document tracking throughout the development lifecycle.
      For the the PDLC of Mimyk please find the document here:  <a href="https://docs.google.com/document/d/1Tw9E3BMnWY1lvLUHCHJhuyteBHftA-jQcsKLFAjOFJY/edit?usp=drive_link">
      PDLC SOP</a>
      </Paragraph>
  <SectionHeading>
   User Guide: How to use the portal
  </SectionHeading>
      <Paragraph>
        Every one with a valid Mimyk email id has access to the portal. The portal is a web-based application that allows users to create, edit, and manage 
        their project's documentation requirements. 
      </Paragraph>
      <SubSectionHeading>
        Concepts in the Portal
        <Paragraph>There are two types of users in the portal: </Paragraph> 
      </SubSectionHeading>
    

      <SubSectionHeading> 
        Administrators 
        <Paragraph>
                  Administrators are responsible for 
                  <NumberedList items={[
                  'Creating and editing projects',
                  'Adding users to projects',
                  'Managing the portal',
                  'Managing the database',
                  'Managing project files',
                ]}/>
      </Paragraph>
        </SubSectionHeading>
    
     <SubSectionHeading> 
      Users 
      <Paragraph>
      Users are responsible for 
      <NumberedList items={[
          'Creating and editing project requirements',
          'Managing project files',
        ]}/>
      </Paragraph>
     </SubSectionHeading>
        
      

      <Paragraph>
      When a user logs into the portal, the side navigation bar allows them to view and select the projects that they have access to. The following sections
      describe the different features of the portal and how to use them.
      </Paragraph>
      <SubSectionHeading>
        Adding requirements to a project
        <Paragraph>
          To add requirements to a project, navigate to the project in the side navigation bar and click on the relevant list from the drop down. 
          Alternately, you can click on the relevant requirements document from the list on the project dashboard page. 
        </Paragraph>
      </SubSectionHeading>

      </Box>
  )
};

export default Home;

