import {React, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, Button, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import {getDocsStatus} from '../utils/requestManager';


const buttonStyle = {
  maxWidth: '400px', 
  maxHeight: '50px', 
  minWidth: '400px', 
  minHeight: '50px'
}

const table = {
  minWidth: 650,
  borderCollapse: 'separate',
  borderSpacing: '0px 4px'
}

const tableRow={
    cursor: "pointer",
    borderLeft: "8px solid #9a031e",
    marginTop: "8px"
  };

 const tableCell= {
    marginTop: "8px"
}


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));


const Project = () => {
   // Get the project ID from the URL
  const [projectId, setProjectId] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const projectData = location.state;
  const [token, setToken] = useState([]);
  const [docStatus, setDocStatus] = useState({
    PRS:'',
    SRS:'',
    SDS:'',
    STP:'',
    ITP:'',
    UTP:''
  });

  const pageDictionary = {
    requirements:['SRS', 'PRS', 'SDS'],
    testPlans: ['ITP', 'STP', 'UTP']
  }

  const baseUrl = process.env.REACT_APP_URI.trim('');

  useEffect(()=>{
    
    setProjectId(projectData.projectId);
    const data = {
      url:`${baseUrl}/auth/get-project-status`,
      sheetId:projectData.sheetId
    }
    getDocsStatus(data).then(data=>{
      if(data){
        setDocStatus(() => {
          const doc = data.reduce((acc, arr) => {
            acc[arr[0]] = arr[0] in docStatus ? arr[1] : 'Unknown';
            return acc;
          }, { ...docStatus }); // Spread to maintain existing keys if any are missing in `data`
        
          return doc;
        });      }
    });
    // Redirect to the specific page when a page button is clicked
    localStorage.setItem('projectData', JSON.stringify({projectData}));
  },[projectData]);


  
  
  const onHandleRequest =  (event)=>{
    const appendData = {
      page:event.target.name
    }
    const queryProjectData = {...projectData, ...appendData};
    if (pageDictionary.requirements.includes(event.target.name)){
      navigate(`/project/requirements/`, {state:queryProjectData});
    }
    else if (pageDictionary.testPlans.includes(event.target.name)){
      navigate(`/project/testplan/`, {state:queryProjectData});
    }
    
  }

  return (
    <Container>
      <br />

      <center>
        <Typography variant="h5" gutterBottom>
          Project Name: {projectData.projectName}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Project ID: {projectId}
        </Typography>      
        <Typography variant="h6" gutterBottom>
          This dashboard lists the basic project documentation requirements for {projectData.projectName} as per IEC 62304 (Software for a Medical Device). 
          The V diagam represents the traceability between requirements and across test plans.
        </Typography>
      </center>
      <br />
      <br />
      <br />
      <br />
      <Box sx={{width:'100%'}}>
       <Grid container spacing={2} alignItems="flex-end">
       <Grid   direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}>
      <Item>
      <TableContainer component={Paper} >
      <Table styles={table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Doc Name</TableCell>
            <TableCell align="right">Status</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          <TableRow styles={tableRow}>
            <TableCell styles={tableCell}>
            <Button style={buttonStyle} name='PRS'variant="contained" color="info" onClick={onHandleRequest}>
                Product Requirements Specification
              </Button>
              </TableCell>
              <TableCell styles={tableCell}>{docStatus.PRS}</TableCell>
              </TableRow>
            <TableRow styles={tableRow}>
              <TableCell styles={tableCell}>
              <Button style={buttonStyle} name='SRS' variant="contained" color="info" onClick={onHandleRequest}>
                System Requirements Specification
              </Button>
              </TableCell>
              <TableCell styles={tableCell}>{docStatus.SRS}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell styles={tableCell}>
              <Button style={buttonStyle} name='SDS' variant="contained" color="info" onClick={onHandleRequest}>
                System Design Specification
              </Button>
              </TableCell>
              <TableCell styles={tableCell}>{docStatus.SDS}</TableCell>
            </TableRow>
        </TableBody>
      </Table>

      </TableContainer>
       </Item> 
      </Grid>
      <Grid   direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={1}>
      <Item>
      <TableContainer component={Paper}>
      <Table styles={table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Doc Name</TableCell>
            <TableCell align="right">Status</TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
          <TableRow styles={tableRow}>
            <TableCell styles={tableCell}>
            <Button style={buttonStyle} name='STP' variant="contained" color="info" onClick={onHandleRequest}>
                System Test Plan
              </Button>
              </TableCell>
              <TableCell styles={tableCell}>{docStatus.STP}</TableCell>
          </TableRow>
          <TableRow styles={tableRow}>
            <TableCell styles={tableCell}>
            <Button style={buttonStyle} name='ITP' variant="contained" color="info" onClick={onHandleRequest}>
                Integration Test Plan
              </Button>
              </TableCell>
              <TableCell styles={tableCell}>{docStatus.ITP}</TableCell>
          </TableRow>
          <TableRow styles={tableRow}>
            <TableCell styles={tableCell}>
            <Button style={buttonStyle} name='UTP' variant="contained" color="info" onClick={onHandleRequest}>
                Unit Test Plan
              </Button>
              </TableCell>
              <TableCell styles={tableCell}>{docStatus.UTP}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      </TableContainer>
      </Item>        
      </Grid>
      </Grid>
      </Box>
      
    </Container>
  );
};

export default Project;
