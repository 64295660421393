import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, TextField, Checkbox, Button, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {sheetAccess, sheetLastViewed} from '../utils/requestManager';
import AddDetailsPopup from '../components/PopupComponents/AddDetailsPopup';
import ViewPdf from '../components/PrintAndExportComponents/ViewPdf';
import htmlToDocx from 'html-to-docx';
import PrintIcon from '@mui/icons-material/Print';
import { saveCache, loadCache, isCacheExpired, clearCache, clearAllCache } from '../utils/cacheManager';
import { ToastContainer, toast } from 'react-toastify'; // Import Toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Toast styles
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import ReviewPopup from '../components/PopupComponents/ReviewPopup';
import SelectTracePopup from '../components/PopupComponents/SelectTracePopup';
import MultiLevel from '../components/ListComponents/MultiLevel';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TableGenerator from '../components/ListComponents/TableGenerator';
import DescriptionIcon from '@mui/icons-material/Description';



const traceDoc = ['PRS', 'SRS', 'SDS'];

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});
theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    salmon: theme.palette.augmentColor({
      color: {
        main: '#FFE5B4',
      },
      name: 'salmon',
    }),
  },
});

const actionButtonStyles = {
  margin: '10px 0 0 0', width: '300px', height: '30px'
}

const Requirements = () => {
  const [requirements, setRequirements] = useState([]);
  const [viewList, setViewList] = useState(false);
  const [list, setList] = useState("View");
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState('');
  const [viewPdf, setViewPdf] = useState(false);
  const [newReqState, setNewReqState] = useState(false);
  const [loading, setLoading] = useState(false);  // Loading state to dim the background
  const [showReviewers, setShowReviewers] = useState(false);
  const [docStatus, setDocStatus] = useState('');
  const [selectReq, setSelectReq] = useState([]);
  const [traced, setTraced] = useState(false);
  const [reqGroup, setReqGroup] = useState([]);
  const [showTraceMenu, setShowTraceMenu] = useState(false);
  const [reload, setReload] = useState(false);
  const [addNewReq, setAddNewReq] = useState(false);
  
  const [newRequirement, setNewRequirement] = useState({
    title: '',
    description: '',
    traceable: false,
    parentRequirement:''
  });

  const location = useLocation();
  const projectData = location.state;
  const projectId = projectData['projectId'];
  const page = projectData['page'];
  const sheetId = projectData['sheetId'];
  const googleDriveId = projectData['googleDriveId'];

  const reqDictionary = {
    SRS: {
      title: 'Software Requirements Specification',
      reqName: 'SWREQ',
      testId: 'IT',
    },
    SDS: {
      title: 'System Design Specification',
      reqName: 'SDREQ',
      testId: 'UT',
    },
    PRS: {
      title: 'Product Requirements Specification',
      reqName: 'PREQ',
      testId: 'ST',
    },
  };

  const [title, setTitle] = useState('');
  const [lastEdited, setLastEdited] = useState({
    timestamp: null,
    user: null,
  });


  const baseUrl = process.env.REACT_APP_URI.trim('');
  const url = `${baseUrl}/auth/dhf/requirements`;

  const CACHE_KEY_REQUIREMENTS = `${projectId}_${page}_RequirementsCache`;
  const CACHE_KEY_DETAILS = `${projectId}_${page}_DetailsCache`;
  const CACHE_KEY_TEST_PLANS = `${projectId}_${reqDictionary[page].testId}P_TestPlansCache`;

  useEffect(() => {
    setTitle(reqDictionary[page].title);
    const cachedRequirements = loadCache(CACHE_KEY_REQUIREMENTS);
    const cachedDetails = loadCache(CACHE_KEY_DETAILS);

    if (cachedRequirements && cachedDetails && !isCacheExpired(CACHE_KEY_REQUIREMENTS)) {
      setRequirements(cachedRequirements);
      setDetails(cachedDetails);
      setReload(false);
    } else {
      const queryString = new URLSearchParams(projectData).toString();
      const data = {
        method: 'GET',
        url: url,
        queryString: queryString,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      };

      sheetAccess(data)
        .then((result) => {
          if (result) {
            setDetails(result.details);
            setRequirements(result.requirements);
            setDocStatus(result.docStatus);
            saveCache(CACHE_KEY_REQUIREMENTS, result.requirements);
            saveCache(CACHE_KEY_DETAILS, result.details);
            
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });

      }
    const data4lastViewed = {
      url:`${baseUrl}/auth/get-change-log`,
      fileId:sheetId
    }
    sheetLastViewed(data4lastViewed).then((result) => {
      if(result){
        setLastEdited({
          timestamp: result.lastModifiedTime,
          user: result.lastModifyingUser.displayName,
        });
      }
}).catch((error) => {console.error('Error: ', error)});
  
}, [projectId, page, projectData, url, reload]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedRequirement = {
      ...newRequirement,
      [name]: type === 'checkbox' ? checked : value,
    };

    setNewRequirement(updatedRequirement);

    if (updatedRequirement.title === '' || updatedRequirement.description === '') {
      setNewReqState(false);
    } else {
      setNewReqState(true);
    }
  };

  const handleSaveChanges = () => {
  let updatedRequirements = requirements.map(({ id, ...rest }) => rest);

    if(newRequirement.title === '' || newRequirement.description === ''){
    }
    else{
      updatedRequirements = [...updatedRequirements, newRequirement];
      setNewRequirement({
        title: '',
        description: '',
        traceable: false,
      });
      clearCache(CACHE_KEY_TEST_PLANS);
      clearCache(CACHE_KEY_REQUIREMENTS);
      clearCache(CACHE_KEY_DETAILS);
    }
     
    
    // Show toast and set loading state
    setLoading(true);
    const savingToastId = toast.info('Saving changes...', { autoClose: true });  // Display a toast message while saving
    
    handleSaveRequirements(updatedRequirements, savingToastId);
    setViewList(false);
  };

  const handleSaveRequirements = async (updatedRequirements, savingToastId) => {
    
    const queryString = `?$projectId=${projectId}`;
    
    const data = {
      method: 'POST',
      url: url,
      queryString: queryString,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data2Post: {
        details: details,
        requirements: updatedRequirements,
        page: page,
        docStatus: docStatus || 'Draft',
        testPage: reqDictionary[page].testId + 'P',
        sheetId: sheetId,
        googleDriveId: googleDriveId,
      },
    };

    sheetAccess(data)
      .then((result) => {
        if (result) {
          clearAllCache();
          setReload(true);
          // Dismiss the "saving" toast and show success toast
          toast.dismiss(savingToastId);
          toast.success('Changes saved successfully!');
        }
      })
      .catch((error) => {
        // Dismiss the "saving" toast and show error toast
        toast.dismiss(savingToastId);
        toast.error('Error saving changes.');
        console.error('Error:', error);
      })
      .finally(() => {
        setLoading(false);  // Stop dimming background when request is complete
      });
  };

  const handleSave = (fromPopup) => {
    setDetails(fromPopup.details);
    setShowDetails(false);
  };

  const handleAssign = (reviewer) => {
    setDocStatus('Under Review');
    setShowReviewers(false);
  }

  
  const handleReqSelection = (e, index, req, checked) => {
    setSelectReq(!checked);
    //Add to group if selected else, remove from group
    reqGroup[index] = e.target.checked? req : reqGroup.pop(req);
    setReqGroup(reqGroup);
   
  }
  

  const handleSaveTrace = (tracedReq) =>{
    requirements.forEach(req=>{
      if(reqGroup.includes(req)) {
        console.log(req);
        req.parentRequirement=tracedReq
      }
    });

    setRequirements(requirements);
    setShowTraceMenu(false);
  }

  return (
    <Container>
      {loading && (
        <div style={overlayStyle}>
          <div className="loader"></div>  {/* You can customize a loader */}
        </div>
      )}
      <br />
      <Typography variant="h5" gutterBottom>
        Project ID: {projectId}
      </Typography>
      <br/>
      <center>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </center>
      <br/>
      <Typography variant="h7" gutterBottom> 
      {lastEdited && <span style={{fontSize: '1.2rem'}}>Last Edited: {JSON.stringify(lastEdited.timestamp)} by {JSON.stringify(lastEdited.user)}</span>}
      </Typography>
    
      
      <br></br>
      <br></br>
      <br></br>
      <Button
       variant="outlined" 
       color="info" 
       onClick={()=>{setShowDetails(true)}} 
       sx={{margin: '10px 0 0 0', width: '100%', height: '30px'}}
       >
        Add Additional Details
      </Button>
      {showDetails && <AddDetailsPopup data={{ showDetails, details }} onSave={handleSave} onClose={(e)=>{setShowDetails(e)}} />}
      <Box mt={4}>
        <MultiLevel item={{title:<Typography variant='h6'>Add New Requirement</Typography>, icon:<DescriptionIcon/>}} handleClick={()=>{setAddNewReq(!addNewReq)}}/>
      </Box>
{ addNewReq && <Box> 
          <TextField
          label="Requirement Title"
          name="title"
          value={newRequirement.title}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Requirement Description"
          name="description"
          value={newRequirement.description}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <Box display="flex" alignItems="center" mt={2}>
          <Checkbox name="traceable" checked={newRequirement.traceable} onChange={handleInputChange} />
          <Typography>Traceable</Typography>
        </Box>
        </Box>}
       <Box ml={0} mt={4}>
        
        </Box> 
      <br></br>
      <Box>
      <MultiLevel item={{title:<Typography variant='h6'>List of Requirements</Typography>, icon:<FormatListNumberedIcon/>}} handleClick={()=>{setViewList(!viewList)}}/>
        
     {viewList && <TableGenerator data={
      {
        columns:[
          { key:"checkbox", label:"Trace", type:"specialCell", columnTitleType:<Button onClick={()=>{setShowTraceMenu(true)}}>Trace</Button>}, 
          { key:"id", label:"Req ID", type:"text" },
          { key: "title", label: "Title", type: "text"},
          { key: "description", label: "Description", type: "text"},
          { key: "parentRequirement", label: "Parent Requirement", type: "text"},
        ],
        rows: requirements.map((req, index) => ({
          checkbox: {
            value: <Checkbox checked={selectReq[index]} onChange={(event)=>handleReqSelection(event, index, req, selectReq[index])}/>
          },
          ...req,
        }))
      }
    } 
     handleTraceClick={()=>{setShowTraceMenu(true)}} handleReqSelect={handleReqSelection}/>}

      </Box>
      
      <ThemeProvider theme={theme}>
      <Box mt={3}>
      <Grid container spacing={2} justify='space-between'>
      <Button sx={actionButtonStyles} variant="contained" color="info" onClick={handleSaveChanges} style={{ marginTop:'10px', marginLeft: '0px' }}>
          Save Changes
        </Button>
      <Button sx={actionButtonStyles} mt={3} variant="contained" color="outlined" endIcon={<PrintIcon />} onClick={()=>{setViewPdf(true)}}>
        Export as PDF
      </Button>
      <Button sx={actionButtonStyles} mt={3} variant='contained' color='salmon' onClick={()=>{setShowReviewers(true)}}>
        Submit for Review
      </Button>
      </Grid>
      </Box>
      </ThemeProvider>
      
      {showTraceMenu && <SelectTracePopup dataForTrace={{showTraceMenu:showTraceMenu, projectId:projectId, page:page, projectData:projectData}} onSave={handleSaveTrace} onClose={()=>{setShowTraceMenu(false)}} />}
      {showReviewers && <ReviewPopup props={{showReviewers:showReviewers, sheetId:sheetId}} onAssign={handleAssign}/>}
      {viewPdf && <ViewPdf settings={{ viewPdf, projectData, requirements, details }} onClose={(e)=>{setViewPdf(e)}} />}
      
      {/* Toast Notification Container */}
      <ToastContainer />

    </Container>
  );
};

// Overlay style for dimming the background during loading
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default Requirements;


