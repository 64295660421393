import React, { useState, useEffect } from 'react';
import { Button, Box, Modal, Select } from '@mui/material';
import { sheetAccess } from '../../utils/requestManager';

const reqHierarchy = ['PRS', 'SRS', 'SDS'];

const SelectTracePopup = ({ dataForTrace, onSave, onClose }) => {
  const [parentList, setParentList] = useState([]);
  const [tracedReq, setTracedReq] = useState('');

  useEffect(() => {
    
    if (!dataForTrace.page) return;
    
    const page = dataForTrace.page;

    if (reqHierarchy.indexOf(page) === 0) return; // No parent if page is the first in hierarchy

    const pageParent = reqHierarchy[reqHierarchy.indexOf(page) - 1];
    
    dataForTrace.projectData.page = pageParent;
    
    
    const queryString = new URLSearchParams(dataForTrace.projectData).toString();
    
    const url = `${process.env.REACT_APP_URI.trim()}/auth/dhf/requirements`

    sheetAccess({
      method: 'GET',
      url: url,
      queryString: queryString,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res && res.requirements) {
          setParentList(res.requirements);
          dataForTrace.projectData.page = page;
        }
      })
      .catch((err) => console.log('Error fetching requirements:', err));
  }, [dataForTrace.page]);


  return (
    <Modal open={dataForTrace.showTraceMenu} onClose={onClose} aria-labelledby="trace-popup-modal">
      <Box sx={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', 
        width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4,
      }}>
        <Select
          native
          value={tracedReq}
          onChange={(e)=>setTracedReq(e.target.value)}
          fullWidth
          inputProps={{ id: 'select-multiple-native' }}
        >
          {parentList.map((req) => (
            <option key={req.id} value={req.id}>
              {req.id} - {req.title}
            </option>
          ))}
        </Select>
        <Box mt={2} textAlign="center">
          <Button variant="contained" onClick={() => {onSave(tracedReq)}}>Save</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SelectTracePopup;
