
const sheetAccess = async (data)=>{
    if(!data.method){
        data.method = 'GET';
    }
    
    const headers = {
        headers: data.headers
    };
    if(data.method==='GET'){
        try{
            // Fetch data from Google Sheet
            // Use Google Sheets API
            const response = await fetch(data.url+`?${data.queryString}`, {method:'GET', credentials:'include', ...headers});
            if(response.ok){
                const data = await response.json();
                return data;
            }
        }
        catch(error){
            console.error('Error fetching data:', error);
        }
    }
    else if(data.method==='POST'){
        try{
            const response = await fetch(data.url+`?${data.queryString}`, {
                method:'POST', 
                credentials:'include',
                ...headers, 
                body:JSON.stringify({data2Post:data.data2Post})
            }
            );
            if(response.ok){
                // alert('Saved successfully !');
            }
        }
        catch(error){
            console.error('Error saving data:', error);
        }

    }

};


const sheetLastViewed = async(data)=>{
    // Convert the UTC time to your timezone (GMT+5:30)
    const options = {
        timeZone: 'Asia/Kolkata', // GMT+5:30 corresponds to the Asia/Kolkata timezone
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };
  
    try{
        const response = await fetch(`${data.url}/${data.fileId}`, {
            method:'GET', 
            credentials:'include', 
        });
        if(response.ok){
            var data = await response.json();
            const lastEditedTime = new Date(data.lastModifiedTime);
            const localTime = new Intl.DateTimeFormat('en-GB', options).format(lastEditedTime);
            data.lastModifiedTime = localTime;       
            return data;
        }
    }
    catch(error){
        console.error('Error receiving data: ', error);
    }
}

const getReviewers = async(data)=>{
    try{
        const response = await fetch(`${data.url}/${data.fileId}`, {
            method:'GET', 
            credentials:'include', 
        });
        if(response.ok){
            var data = await response.json();
            return data;
        }
    }
    catch(error){
        console.error('Error receiving data: ', error);
    }
}

const getDocsStatus = async(data)=>{
    try{
        const response = await fetch(`${data.url}/${data.sheetId}`, {
            method:'GET', 
            credentials:'include', 
        });
        if(response.ok){
            var data = await response.json();
            return data;
        }
    }
    catch(error){
        console.error('Error receiving data: ', error);
    }
}

const getProjectsList = async(data)=>{
    try{
        const response = await fetch(`${data.url}`, {
            method:'GET', 
            credentials:'include', 
        });
        if(response.ok){
            var data = await response.json();
            return data;
        }
    }
    catch(error){
        console.error('Error receiving data: ', error);
    }
}

const checkLoginStatus = async () => {
  try {
    // Make a request to check if the user is authenticated
    const response = await fetch(`${process.env.REACT_APP_URI.trim('')}/auth/user`, {
      method: 'GET',
      credentials: 'include',  // Send cookies with the request
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
        return{message:'success', user:response.json()};
    }
  } catch (error) {
    console.error('Error verifying login status:', error);
    return{message:'error', error:error};
  }
};

const createProject  = async (data)=>{
    try{
        const response = await fetch(`${data.url}/${data.fileId}`, {
            method:'POST', 
            credentials:'include', 
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({data2Post:data.data2Post})
        });
        if(response.ok){
            return response.json();
        }
    }   
    catch(error){
        console.error('Error receiving data: ', error);
    }
};


export {sheetAccess, sheetLastViewed, getReviewers, getDocsStatus, getProjectsList, checkLoginStatus, createProject};