import React, { useEffect, useState } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { saveCache, loadCache, isCacheExpired, clearCache } from '../utils/cacheManager'; // Import cacheManager functions
import { TablePagination } from '@mui/base/TablePagination';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import {TextField, Button } from '@mui/material/';
import {createProject} from '../utils/requestManager';

const CreateProject = () => {
  const navigate = useNavigate();
  
  const handleCreateProject = () => {
    const url = `${(process.env.REACT_APP_URI || '').trim()}/auth/project/create`;
    console.log(url);
  };

  return(
        <div>
            <Typography variant="h4" gutterBottom>
                Create New Project
            </Typography>
            <Box mt={1} p={5}>
                <Typography variant="h6" gutterBottom>
                    Please fill in the required fields.
                </Typography>
                {/* Form fields */} 
                <Box mt={1} p={5}>
                    <Typography variant="h6" gutterBottom>
                        Project Name
                    </Typography>
                    <TextField id="projectName" label="Project Name" variant="outlined" fullWidth />
                </Box>
                <Box mt={1} p={5}>
                    <Typography variant="h6" gutterBottom>
                        Project Description
                    </Typography>
                    <TextField id="projectDescription" label="Project Description" variant="outlined" fullWidth />
                </Box>
                <Box mt={1} p={5}>
                    <Typography variant="h6" gutterBottom>
                        Project URL
                    </Typography>
                    <TextField id="projectURL" label="Project URL" variant="outlined" fullWidth />
                </Box>
                <Box mt={1} p={5}>
                    <Typography variant="h6" gutterBottom>
                        Project Owner
                    </Typography>
                    <TextField id="projectOwner" label="Project Owner" variant="outlined" fullWidth />
                </Box>
                <Box mt={1} p={5}>
                    <Typography variant="h6" gutterBottom>
                        Project Team
                    </Typography>
                    <TextField id="projectTeam" label="Project Team" variant="outlined" fullWidth />
                </Box>
                <Box mt={1} p={5}>
                    <Typography variant="h6" gutterBottom>
                        Project Type
                    </Typography>
                    <TextField id="projectType" label="Project Type" variant="outlined" fullWidth />
                </Box>
                <Box mt={1} p={5}>
                    <Typography variant="h6" gutterBottom>
                        Project Tags
                    </Typography>
                    <TextField id="projectTags" label="Project Tags" variant="outlined" fullWidth />
                </Box>
            </Box>

            {<Box mt={1} p={5}>
                <Button variant="contained" color="info" onClick={handleCreateProject}>
                    Create Project
                </Button>   
            </Box>}
        </div>
  );

}

export default CreateProject;
