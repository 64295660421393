import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import TurndownService from 'turndown';

const modalStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: '20px',
  height: '100vh',
  width: '100vw',
};

const pdfViewerStyle = {
  width: '100%',
  height: '80vh',
  border: 'none',
};

const ViewPdf = ({ settings, onClose }) => {
  const [viewPdf, setViewPdf] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [requirements, setRequirements] = useState([]);
  const [details, setDetails] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    setViewPdf(settings.viewPdf);
    setProjectData(settings.projectData || null);
    setRequirements(settings.requirements);
    setDetails(settings.details);
  }, [settings]);


useEffect(() => {
  if (viewPdf && projectData && requirements.length) {
    generatePdf();
  }
}, [viewPdf, projectData, requirements, details]);

  const handleClosePdf = () => {
    onClose(false);
  };

  const convertHtmlToMarkdown = (htmlContent) => {
    const turndownService = new TurndownService();
  
    // Convert HTML to Markdown
    const markdown = turndownService.turndown(htmlContent);
    return markdown;
  };

  // Function to generate Markdown from project data
  const generateMarkdown = () => {
    const mdDetails = convertHtmlToMarkdown(details);
  
    // Generate TOC manually
    let markdown = `# ${projectData?.projectName || 'Project Name'}\n\n`;
    markdown += `**Project ID:** ${projectData?.projectId || 'N/A'}\n\n`;
    markdown += `## Table of Contents\n\n`;
    markdown += `1. [Details](#details)\n`;
    markdown += `2. [Requirements](#requirements)\n\n`;
  
    // Add Details section
    markdown += `## Details\n${mdDetails || 'No details provided.'}\n\n`;
  
    // Add Requirements Table
    markdown += `##  Requirements \n\n`;
    
    requirements.forEach((req, index) => {
      markdown += `*** \n\n`;
      markdown += `${index+1}. `
      markdown += `**${req.id}: ${req.title}**\n\n`;
      markdown += `This requirement is traced to ${req.parentRequirement}\n\n`;
      markdown +=`\n\n`;
      markdown += `${req.description}\n\n`;
    });
  
    // Ensure all images in Details have fixed sizes using <img> tags
    markdown = markdown.replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1" width="400" height="300" />');
    return markdown;
  };
  

  const generatePdf = async () => {
    const markdownContent = generateMarkdown();
    const url = process.env.REACT_APP_URI.trim();
  
    try {
      const response = await axios.post(
        `${url}/auth/generate-pdf`,
        { markdownContent, fileName: `${projectData?.projectName || 'Project'}` },
        { responseType: 'blob',  
          withCredentials: true
        } // Ensure response is binary, 
        
      );
  
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const blobUrl = window.URL.createObjectURL(blob);
  
      // Revoke old Blob URL to free up memory
      if (pdfUrl) {
        window.URL.revokeObjectURL(pdfUrl);
      }
  
      setPdfUrl(blobUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  
  useEffect(() => {
    // Cleanup Blob URL on component unmount
    return () => {
      if (pdfUrl) {
        window.URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);
  
  return (
    <Modal open={viewPdf} onClose={handleClosePdf}>
      <Box style={modalStyle}>
        {pdfUrl ? (
          <iframe src={pdfUrl} style={pdfViewerStyle} title="PDF Viewer" />
        ) : (
          <div>Loading...</div>
        )}
        <Button onClick={handleClosePdf} variant="contained" style={{ marginTop: '20px' }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ViewPdf;
