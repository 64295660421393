import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import './Editor.css'; // Import custom styles

/* 
 * Functional Editor component that takes placeholder text as a prop 
 */
const Editor = ({ prevText, sendTextFromEditor }) => {
  const [editorHtml, setEditorHtml] = useState(prevText); // Manage editor content
  
  const [theme, setTheme] = useState('snow'); // Manage theme


  useEffect(() => {
  }, [editorHtml]);

  // Handle content change
  const handleChange = (html) => {
    setEditorHtml(html);
    sendTextFromEditor(html); // Send updated text to parent component
  };


  return (
    <div className='editor-container'>
      <ReactQuill 
        theme={theme}
        value={editorHtml}
        onChange={handleChange}
        modules={Editor.modules}
        formats={Editor.formats}
        bounds={'.app'}
        
      />
    </div>
  );
};

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false, // Toggle to add extra line breaks when pasting HTML
  },
};

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video',
];



export default Editor;
