import React, {useState, useEffect} from 'react';
import { styled } from '@mui/system';
import { Card, CardContent, CardHeader, Select, MenuItem, Modal, Box, Button } from '@mui/material';
import {getReviewers} from '../../utils/requestManager';
import Grid from '@mui/material/Grid2';

const ReviewPopup = ({props, onAssign}) => {
  const [showCard, setShowCard] = useState(false);
  const [reviewersList, setReviewersList] = useState([]);
  const [selectedReviewer, setSelectedReviewer] = useState('');
  

  useEffect(()=>{
    setShowCard(props.showReviewers);
    const baseUrl = process.env.REACT_APP_URI.trim('');
    const sheetId = props.sheetId;
    getReviewers({
     url:`${baseUrl}/auth/get-reviewers`,
     fileId:sheetId 
    }).then(data => {
      if(data){
        setReviewersList(data);
      }
      
    });
  },[props]);


  return (
      <Modal
        open={showCard}
        onClose={() => setShowCard(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: 300,   // Square dimensions
            height: 300,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.paper', // Background color from theme
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <Box ml={2} mt={2} pt={2} mr={2}> 
         <Select variant="standard" defaultValue="" fullWidth onChange={(e)=>{setSelectedReviewer(e.target.value)}}>
                {reviewersList.map((reviewer, index) => (
                  <MenuItem key={index} value={reviewer.email}>
                    {reviewer.name}
                  </MenuItem>
                ))}
              </Select>
          </Box>
          <Box ml={2} mt={2} pt={2} mr={2}> 
            <Grid container spacing={4} justify='space-between'>
            <Grid item><Button variant='contained' color='info' onClick={()=>{onAssign(selectedReviewer)}} > Assign and Submit </Button></Grid>
            <Grid item><Button variant='outlined' color='' onClick={()=>{setShowCard(false)}} > Cancel </Button></Grid>
            </Grid>
            </Box>
        </Box>
      </Modal>
    )
};


export default ReviewPopup;
