import React, {useEffect} from 'react';
import { Button, Container, Typography, Paper, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { checkLoginStatus } from '../utils/requestManager';

const LOGO_HEIGHT = 150;
const LOGO_ASP_RATIO = 3.29;

const Login = () => {
  const navigate = useNavigate();
  const handleGoogleLogin = () => {
    const url = `${(process.env.REACT_APP_URI || '').trim()}/auth/google`;
    window.location.href = url;  // Redirect to the Google OAuth flow
  };

  useEffect(()=>{
    
    checkLoginStatus().then((res) => {
      
      if(res.message=='success'){
        navigate('/home');
      }
    }).catch((err) => {
      console.error('Error:', err);
      navigate('/');
    })
}, []);

  return (
    <Container>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Box mt={0} p={10}>
      <Paper  >
      <center>
      <Box mt={0} p={5}>
      <img src="/MIMYK_Logo.svg" 
      height ={LOGO_HEIGHT} 
      width={LOGO_HEIGHT * LOGO_ASP_RATIO}
      />
      <Box mt={8}>
      <Typography variant="h4" gutterBottom
      sx={{color:'info.main'}}
      >
        DHF Portal
      </Typography>
      </Box>
      </Box>
      <Box sx={{pb:8}}>
      <Button variant="contained" color="info" onClick={handleGoogleLogin}>
        Login with Google
      </Button>
      </Box>
      
      </center>
      </Paper>
      </Box>
      </Grid>
    </Container>
  );
};

export default Login;
